import React from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Container, Row, Col } from "reactstrap";
import Layout from "../components/layout";
import * as f from "../constants/fieldNames";
import * as ev from "../constants/analyticsEvents";
import { LOAN_TERM, LOAN_AMOUNT } from "../constants/fieldNames";

const mapStateToProps = ({ data }) => {
  return { data };
};

const Apply = ({ data }) => {
  return (
    <Layout showFullNav={false} showFooter={false}>
      <Helmet
        title="Apply for a loan"
        meta={[
          { name: "description", content: "Apply for a loan" },
          { name: "robots", content: "noindex, nofollow" },
        ]}
      >
        <html lang="en" />
        <script type="text/javascript" id="clearChoice_config">
          {`
          window.clearChoice_conf = window.clearChoice_conf || {};
          clearChoice_conf = {
            key: 'EBAA85DE-BBA9-4190-8C4D-11DAC46B7948',
            ref: '${data[f.AFFILIATE_REF]}',
            gclid: '${data[f.GCLID]}',
            elemId: 'ccApply',
            submitText: "Get my quote",
            mode: "Results",
            loanAmount: ${
              typeof window !== "undefined"
                ? window.localStorage.getItem(`__${f.LOAN_AMOUNT}`)
                : 2000
            },
            loanTerm: ${
              typeof window !== "undefined"
                ? window.localStorage.getItem(`__${f.LOAN_TERM}`)
                : 12
            },
            theme: 'ChooseWisely',
            laMax: 10000,
            laMin: 100,
            ltMax: 60,
            ltMin: 3,
            ltStep: 1,
            showMarketingStage: false,
            fixedControls: true,
            onSubmit: function(formData){
              ga('send', {
                hitType: 'event',
                eventCategory: '${ev.FORM_CATEGORY}',
                eventAction: '${ev.FORM_ACTION_SUBMIT}',
                eventLabel: 'la:' + formData.${LOAN_AMOUNT} + ' lt: ' + formData.${LOAN_TERM}
              });
            }
          };
        `}
        </script>
      </Helmet>
      <Container className="application-form-container">
        <Row>
          <Col>
            <div id="ccApply" />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default connect(mapStateToProps)(Apply);
